import React from "react";
import {graphql} from "gatsby";
import TrabajaConNosotros from "../../components/empleos/trabaja-con-nosotros";

const pageData = {
    title: 'Trabajá con nosotros',
    description: 'Potenciá tu carrera laboral ingresando a la compañía líder del Mercado de Capitales. Conocé nuestras búsquedas de empleos y postulate.'
};

const TrabajaConNosotrosPage = ({data}) => {
    return <TrabajaConNosotros pageData={pageData} jobPositions={data.allSearches.nodes} />
    
}

export const query = graphql`
  query {
    allSearches {
      nodes {
        id_search
        position_name
        timestamp
        timestamp_approve
        seniority_name
        description_task
        profile_description
        id_departament
        departament
        area
        name
        sector
      }
    }
  }
`

export default TrabajaConNosotrosPage